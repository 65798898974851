/* eslint-disable no-restricted-globals */
import { parse, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import logger from './logger';

const formatUTCDate = (date, formatDate) => {
  try {
    if (date) {
      return format(utcToZonedTime(date, 'UTC'), formatDate, {
        timeZone: 'UTC',
      });
    }
    return '-';
  } catch (error) {
    logger.log({ error });
    return '-';
  }
};

export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const day = date.getDate();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];

  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}`;
}

export const parseDate = (date) => {
  if (!date) return '';

  const formats = [
    'yyyy-MM-dd',
    'yyyy.MM.dd',
    'MM/dd/yyyy',
    'dd-MM-yyyy',
    'dd/MM/yyyy',
    'dd.MM.yyyy',
    'yyyy/MM/dd',
    'yyyyMMdd',
    'ddMMyyyy',
    'MMM dd, yyyy',
    'dd MMM yyyy',
    'yyyy-MM-dd HH:mm:ss',
    'MM/dd/yyyy HH:mm:ss',
    'dd/MM/yyyy HH:mm:ss',
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const fmt of formats) {
    const parsed = parse(date.toString(), fmt, new Date(), { locale: fr });
    if (!isNaN(parsed.getTime())) return format(parsed, 'dd/MM/yyyy');
  }

  return 'Invalid Date';
};
export default formatUTCDate;
